import React from "react"
import imgPortadaEbook from "@components/pageEbook/ebooks/ebook17/image/imgPortadaEbook.png"
import img1 from "@components/pageEbook/ebooks/ebook17/image/imgCard1.svg"
import img2 from "@components/pageEbook/ebooks/ebook17/image/imgCard2.svg"
import img3 from "@components/pageEbook/ebooks/ebook17/image/imgCard3.svg"
import slider1 from "@components/pageEbook/ebooks/ebook17/image/imgSilder1.png"
import slider2 from "@components/pageEbook/ebooks/ebook17/image/imgSilder2.png"
import slider3 from "@components/pageEbook/ebooks/ebook17/image/imgSilder3.png"

const title = (
  <>
  <h1 className="titles-underlined-ebook ebook-ventas">
    Tendencias de la comunicación digital en la educación en 2024   
    </h1>
  </>
)

const textDescription = (
  <p className="text-h2-ebook" style={{ marginTop: "20px" }}>
    En 2024, la tecnología redefine la educación, optimizando los procesos de matrículas y automatizando tareas administrativas.
    <br /><br />
    Sin embargo, la transición a lo digital presenta desafíos significativos, incluyendo la adaptación de sistemas y la capacitación de personal para manejar nuevas herramientas y plataformas.
  </p>
)

const linkForm = (
  <span>
    Al registrarte, aceptas los términos y condiciones de nuestra{" "}
    <a
      style={{ color: "#2C5C82", textDecoration: "none" }}
      href="https://beexcc.com/politica-seguridad-privacidad.html"
    >
      Política de Privacidad.{" "}
    </a>
    <br />
    <span> ¡Descuida que te contactaremos solo por cosas importantes!</span>
  </span>
)

const textOne = (
  <p>
    El proceso de admisión en las instituciones educativas solía ser un desafío considerable, tanto para los estudiantes como para el personal administrativo. Tradicionalmente, los estudiantes tenían que formarse durante horas para entregar documentos y formularios de admisión.
    <br /><br />
    Además, era común que tuvieran que visitar repetidamente la institución para consultar el estado de su solicitud.
  </p>
)
const textTwo = (
  <p>
    Los chatbots en la educación han transformado la comunicación institucional, beneficiando tanto a estudiantes como a personal docente.
    <br /><br />
    Estas herramientas, basadas en inteligencia artificial, ofrecen una interacción dinámica que facilita el aprendizaje y optimiza el tiempo de los educadores.
  </p>
)
const textThree = (
  <p>
    Uno de los principales problemas es la falta de una estrategia clara y la resistencia al cambio. Durante la pandemia, la necesidad de implementar sistemas automatizados se volvió imperativa, pero no hubo tiempo suficiente para planificar adecuadamente su integración.
  </p>
)

const data = {
  start: {
    support: "E-book | Educación",
    title: title,
    description: textDescription,
    classtext: "",
    link: linkForm,
    href: "/ebook-8.pdf",
    brochure: "Ebook",
    img: imgPortadaEbook,
  },

  slider: {
    title: true,
    underlined: "encontrarás?",
    checklist: [
      {
        key: 1,
        text: "Uso de la tecnología para los procesos de matrículas",
      },
      {
        key: 2,
        text: "  Automatizar los procesos administrativos",
      },
      {
        key: 3,
        text: "Transformación en las metodologías",
      },
      {
        key: 4,
        text: " Uso de los bots en las instituciones",
      },

      {
        key: 5,
        text: "Beneficios del uso de los bots",
      },
      {
        key: 6,
        text: "Tendencias que marcarán el camino al éxito",
      },
    ],
    listPictures: [
      {
        key: 1,
        img: slider1,
      },
      {
        key: 2,
        img: slider2,
      },
      {
        key: 3,
        img: slider3,
      },
    ],
  },

  description: {
    cards: [
      {
        title: "Uso de la tecnología para mejorar los procesos de matrículas",
        text: textOne,
        photo: img1,
        class: true,
      },
      {
        title: "Uso de los bots en las instituciones",
        text: textTwo,
        photo: img2,
        class: "",
      },
      {
        title: "Desafíos de la digitalización en la educación",
        text: textThree,
        photo: img3,
        class: true,
      },
    ],
  },
}

export default data
